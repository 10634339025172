import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { firebase, FirebaseUIModule } from 'firebaseui-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AppMaterialModule } from './material-module';
import { CommonUtil } from './shared/common.util';
import { ConfigService } from './shared/services/config.service';

import { environment } from 'src/environments/environment';
import { MenusComponent } from './shared/components/menus/menus.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { ProfileComponent } from './pages/profile/profile.component';
import { LoginComponent } from './pages/login/login.component';
import { BrandingConfigService } from './shared/services/branding.service';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { NgwWowModule } from 'ngx-wow';
import { AngularFireModule } from '@angular/fire/compat';
import { ApplyClaimComponent } from './pages/apply-claim/apply-claim.component';
import { DataTableComponent } from './shared/components/data-table/data-table.component';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { ClaimsTableComponent } from './shared/components/claims-table/claims-table.component';
import { OwnerRequestFormViewComponent } from './pages/owner-request-form-view/owner-request-form-view.component';
import { ViewDocsComponent } from './pages/view-docs/view-docs.component';
import { SignInPadComponent } from './shared/sign-in-pad/sign-in-pad.component';
import { NgxPrintModule } from 'ngx-print';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { DatePipe } from '@angular/common';
import { OwnersComponent } from './pages/owners/owners.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD,
    },
  ],
  tosUrl: '/terms',
  privacyPolicyUrl: '/privacy',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MenusComponent,
    LoginComponent,
    HomeComponent,
    ProfileComponent,
    ApplyClaimComponent,
    DataTableComponent,
    ClaimsTableComponent,
    OwnerRequestFormViewComponent,
    ViewDocsComponent,
    SignInPadComponent,
    AdminDashboardComponent,
    OwnersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment?.firebaseConfig),
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyMaterialModule,
    NgwWowModule,
    NgxPrintModule,
  ],
  providers: [
    CommonUtil,
    Title,
    DatePipe,
    ConfigService,
    AuthGuard,
    BrandingConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: BrandingConfigService) => () => config.load(),
      deps: [BrandingConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
