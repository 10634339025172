import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { ApplyClaimComponent } from './pages/apply-claim/apply-claim.component';
import { ViewDocsComponent } from './pages/view-docs/view-docs.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { OwnersComponent } from './pages/owners/owners.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'docs/:id',
    component: ViewDocsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'owners',
    component: OwnersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: ApplyClaimComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
