<div class="main-container">
  <!-- Header -->
  <div class="header">
    <mat-toolbar color="primary">
      <ng-container *ngIf="user?.admin">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </ng-container>
      <div class="mx-2">
        <span>{{ appname }}</span>
      </div>
      <span class="spacer"></span>

      <ng-container *ngIf="!user?.uid; else logoutview">
        <button routerLink="login" mat-button color="accent">Login</button>
      </ng-container>
      <ng-template #logoutview>
        <button (click)="onLogout()" mat-button color="accent">Logout</button>
      </ng-template>
    </mat-toolbar>
  </div>

  <!-- Admin Navigation -->
  <ng-container *ngIf="user?.admin; else userView">
    <div class="app-container">
      <mat-sidenav-container fullscreen class="sidenav-container">
        <mat-sidenav
          #sidenav
          mode="side"
          opened
          fixedTopGap="60"
          fixedBottomGap="100"
        >
          <mat-nav-list>
            <a class="mat-icon-text" mat-list-item routerLink="/home">
              <mat-icon>home</mat-icon>
              <span>Home</span>
            </a>
            <a class="mat-icon-text" mat-list-item routerLink="/claims">
              <mat-icon>assignment</mat-icon>
              <span>Claims</span>
            </a>
            <a class="mat-icon-text" mat-list-item routerLink="/send-email">
              <mat-icon>email</mat-icon>
              <span>Send Email</span>
            </a>
            <a class="mat-icon-text" mat-list-item routerLink="/owners">
              <mat-icon>people</mat-icon>
              <span>Owners</span>
            </a>
            <a class="mat-icon-text" mat-list-item routerLink="/profile">
              <mat-icon>person</mat-icon>
              <span>My Profile</span>
            </a>
            <a
              class="mat-icon-text"
              mat-list-item
              *ngIf="!user?.uid"
              routerLink="/login"
            >
              <mat-icon>login</mat-icon>
              <span>Login</span>
            </a>
            <a
              class="mat-icon-text"
              mat-list-item
              *ngIf="user?.uid"
              routerLink="/logout"
            >
              <mat-icon>logout</mat-icon>
              <span>Logout</span>
            </a>
          </mat-nav-list>
        </mat-sidenav>

        <!-- Main Content for Admins -->
        <mat-sidenav-content>
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </ng-container>

  <!-- Non-Admin Content -->
  <ng-template #userView>
    <div class="app-container">
      <div
        class="d-flex justify-content-center align-items-center language-translate"
      >
        <div>Choose a Language</div>
        <div class="text-center" id="google_translate_element"></div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </ng-template>

  <!-- Footer-->
  <tt-footer
    class="footer p-3 text-center"
    style="background-color: #673ab7 !important"
  ></tt-footer>
</div>
